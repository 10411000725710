import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import BlueButton from "../../components/BlueButton";
import TabBar from "../../components/TabBar";
import { careersFields } from "../../metadata";
import Layout from '../../components/layout';
import { graphql } from 'gatsby';

const styles = theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing.unit * 22,
      paddingRight: theme.spacing.unit * 22,
      paddingTop: theme.spacing.unit * 5,
      paddingBottom: theme.spacing.unit * 5
    }
  },
  title: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
    '&:last-of-type': {
      marginBottom: "40px"
    },
  },
  subheading: {
    ...theme.typography.article,
    textTransform: "uppercase",
    paddingTop: theme.spacing.unit * 3,
    textAlign: "left",
    fontSize: "20px",
    color: "#777777",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  space: {
    marginTop: "50px"
  },
  space2: {
    marginTop: "40px"
  },
  subTitle: {
    ...theme.typography.article,
    textTransform: "uppercase",
    paddingTop: theme.spacing.unit * 3,
    width: "fit-content",
    borderBottom: "6px solid #6f73ff",
    marginTop: "20px"
  },
  button: {
    width: "260px",
    height: "39px"
  },
  list: {
    marginLeft: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    listStyle: "disc"
  }
});

const CareersReactPageTemplate = ({ data, classes, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout location={location}>
      <React.Fragment>
      <TabBar fields={careersFields} />
      <Grid
        container
        className={classes.container}
        justify="space-between"
        spacing={40}
      >
        <Grid item xs={12}>
          <Typography className={classes.title} gutterBottom>
            {frontmatter.title}
          </Typography>
          <Typography className={classes.paragraph}>
            {frontmatter.description}
          </Typography>
          <Typography
            className={classNames(classes.space2, classes.subheading)}
            gutterBottom
          >
            {frontmatter.location.title}
          </Typography>
          <Typography className={classes.paragraph}>
            {frontmatter.location.description}
          </Typography>
          <Typography
            className={classNames(classes.space2, classes.subheading)}
            gutterBottom
          >
            {frontmatter.duties.title}
          </Typography>
          <Typography className={classes.paragraph}>
            {frontmatter.duties.description}
          </Typography>
          <ul className={classNames(classes.list)}>
            {frontmatter.duties.list
              .split("|")
              .map((v, k) => k > 0 && <li key={k}>{v}</li>)}
          </ul>
          <Typography
            className={classNames(classes.space2, classes.subheading)}
            gutterBottom
          >
            {frontmatter.requirements}:
          </Typography>
          <Typography
            className={classNames(classes.space2, classes.subheading)}
            gutterBottom
          >
            {frontmatter.mustHave.title}
          </Typography>
          <ul className={classNames(classes.list)}>
            {frontmatter.mustHave.description
              .split("|")
              .map((v, k) => k > 0 && <li key={k}>{v}</li>)}
          </ul>
          <Typography
            className={classNames(classes.space2, classes.subheading)}
            gutterBottom
          >
            {frontmatter.additional.title}
          </Typography>
          <ul className={classNames(classes.list)}>
            {frontmatter.additional.description
              .split("|")
              .map((v, k) => k > 0 && <li key={k}>{v}</li>)}
          </ul>
          <Typography
            className={classNames(classes.space2, classes.subheading)}
            gutterBottom
          >
            {frontmatter.hiringProcess.title}
          </Typography>
          <ul className={classNames(classes.list)}>
            {frontmatter.hiringProcess.list
              .split("|")
              .map((v, k) => k > 0 && <li key={k}>{v}</li>)}
          </ul>
          <Typography
            className={classNames(classes.space2, classes.subheading)}
            gutterBottom
          >
            {frontmatter.about.title}
          </Typography>
          {frontmatter.about.description.split("|").map((v, k) => (
            <Typography
              key={k}
              className={classNames(classes.paragraph)}
              gutterBottom
            >
              {v}
            </Typography>
          ))}
          <BlueButton
            text="Apply Now"
            styles={classNames(classes.button, classes.space2)}
            onClick={() =>
              (window.location.href = `mailto:careers@primoko.com`)
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
    </Layout>
  );
};

export default withStyles(styles)(CareersReactPageTemplate);

export const careerReactPageQuery = graphql`
  query CareersReactPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        description
        location {
          title
          description
        }
        duties {
          title
          description
          list
        }
        requirements
        mustHave {
          title
          description
        }
        additional {
          title
          description
        }
        hiringProcess {
          title
          list
        }
        about {
          title
          description
        }
      }
    }
  }
`;
